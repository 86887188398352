import React from 'react'
import CategoryList from './pages/category/CategoryList.js'






import { BrowserRouter, Route, Routes } from 'react-router-dom'
import VideoList from './pages/video/VideoList.js'
import YogaposesCategoryList from './pages/yogaposesCategory/YogaposesCategoryList.js'
import YogaPosesList from './pages/YogaPoses/YogaPosesList.js'
import Login from './pages/login.js'
import ArticleList from './pages/articals/ArticalList.js'



function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={ <Login></Login> } /> */}
        {/* Product, buyer, seller */}
        {/* <Route path="/hotels" element={<HotelsList></HotelsList>} /> */}
        {/* <Route path="/sliders" element={<SliderList/>} />
        
         } />
        
          */}
        <Route path="/" element={<Login />} />
        <Route path="/categoryList" element={<CategoryList />} />
        <Route path="/videos" element={<VideoList />} />
        <Route path="/Articles" element={<ArticleList />} />
        <Route path='/yogaposesCategory' element={<YogaposesCategoryList />} />
        <Route path='/yogaposes' element={<YogaPosesList />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
