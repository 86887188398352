import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "./config";


function Sidebar(){

   const navigation=useNavigate()
const gotoDashboard=(screen)=>{
    navigation(`/${screen}`);
}
    
   

 

    const getLogout = ()=>{
        axios.post(`${config.serverURL}/users/logout`,{userId : localStorage.getItem("userId")})
        .then((res)=>{
            console.log(res.data);
            if(res.data.description==="User is Logged Out")
            {
                localStorage.removeItem("token");
                localStorage.removeItem('userId');
                return navigation("/");
            }else{
                localStorage.removeItem("token");
                localStorage.removeItem('userId');
                return navigation("/");
            } 
        },{headers:{"token" : localStorage.getItem("token")}}) 
    }

    return(
        <div className="col-lg-1 col-2 sidebarbg">

            <div className="row" >
               
               
               
                <div className="col-12" onClick={()=>gotoDashboard('categoryList')}>
                    <img className="dashboard" src="https://cdn-icons-png.flaticon.com/512/2603/2603910.png" />
                    <p className="filter" style={{color:'black',textAlign:'center'}}>Article Category</p>
                </div>
              
              
                <div className="col-12" onClick={()=>gotoDashboard('Articles')}>
                    <img className="dashboard" src="https://static.thenounproject.com/png/3407390-200.png" />
                    <p className="filter" style={{color:'black',textAlign:'center'}}>Articles</p>
                </div>
                <div className="col-12" onClick={()=>gotoDashboard('videos')}>
                    <img className="dashboard" src="https://t3.ftcdn.net/jpg/01/09/40/34/360_F_109403483_qocRmeSFXJ6KlF3yoaDBuI3CZOiNGfCw.jpg" />
                    <p className="filter" style={{color:'black',textAlign:'center'}}>Video</p>
                </div>
                <div className="col-12" onClick={()=>gotoDashboard('yogaposesCategory')}>
                    <img className="dashboard" src="https://cdn-icons-png.flaticon.com/512/2603/2603910.png" />
                    <p className="filter" style={{color:'black',textAlign:'center'}}>Yoga Poses Category</p>
                </div>
                <div className="col-12" onClick={()=>gotoDashboard('yogaposes')}>
                    <img className="dashboard" src="https://static.thenounproject.com/png/3407390-200.png" />
                    <p className="filter" style={{color:'black',textAlign:'center'}}>Yoga Poses</p>
                </div>
                
             
            
                
                
               
                {/* <div className="col-12" onClick={getLogout}>
                    <img className="logout" src="images/logout.png" />
                    <p className="filter" style={{color:'black',textAlign:'center'}}>Logout</p>
                
           </div> */}
           </div>
        </div>
    )
}

export default Sidebar;