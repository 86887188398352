import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";
import Select from 'react-select';
import config from "../../config";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return new Promise((resolve, reject) => {
      const file = this.loader.file;
      console.log("File received:", file);
      console.log("Is file a Promise?", file instanceof Promise);

      if (file instanceof Promise) {
        file.then(actualFile => {
          console.log("Actual file after resolving promise:", actualFile);
          this._processFile(actualFile, resolve, reject);
        }).catch(err => {
          console.error("Error resolving file promise:", err);
          reject("File promise resolution failed.");
        });
      } else {
        this._processFile(file, resolve, reject);
      }
    });
  }

  _processFile(file, resolve, reject) {
    console.log("Processing file:", file);

    if (file instanceof File) {
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if (validTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = reader.result;
          resolve({
            default: base64String,
          });
        };

        reader.onerror = () => {
          reject("Couldn't read the file.");
        };

        reader.readAsDataURL(file);
      } else {
        reject("Invalid file type. Please upload a jpg, jpeg, or png image.");
      }
    } else {
      reject("The provided file is not a valid File object.");
    }
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }
}


function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

function VideoInfo(props) {
  const [value, setValue] = useState(0);
  const videoList = props?.videoData;
  const [title, setTitle] = useState(videoList?.title || '');
  const [url, setUrl] = useState(videoList?.url || '');
  const [description, setDescription] = useState(videoList?.description || '');
  const [related_yoga_poses, setRelatedYogaPoses] = useState(videoList?.related_yoga_poses || []);
  const [yogaPoses, setYogaPoses] = useState([]);

  useEffect(() => {
    // Fetch yoga poses from the API
    axios.get(`${config.serverURL}admin/yogapose/getall`)
      .then(response => {
        if (response.data.status_code === 200) {
          setYogaPoses(response.data.data.map(pose => ({
            value: pose.id,
            label: pose.name,
          })));
        }
      })
      .catch(error => {
        console.error("There was an error fetching the yoga poses!", error);
      });
  }, []);

  useEffect(() => {
    if (props.addnew) {
      setDescription('');
      setTitle('');
      setRelatedYogaPoses([]);
      setUrl('');
    } else if (videoList) {
      // Update state when videoList changes
      setTitle(videoList.title || '');
      setUrl(videoList.url || '');
      setDescription(videoList.description || '');
      setRelatedYogaPoses(videoList.related_yoga_poses?.map(pose => pose.id) || []);
    }
  }, [props.addnew, videoList]);

  const onChangeHandler = () => {
    let data = {
      title,
      url,
      description,
      related_yoga_poses,
    };
    console.log(title, url, description, related_yoga_poses, "title,url,description,related_yoga_poses..")
    if (props.addnew) {
      if (title.length < 0) {
        return;
      }
      axios.post(
        `${config.serverURL}admin/video/add`,
        data
      )
        .then(() => {
          toast("Successfully Created", {
            position: "bottom-center",
            type: "success",
          });
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      data = {
        video_id: videoList.id, title,
        url,
        description,
        related_yoga_poses
      }
      axios.post(
        `${config.serverURL}admin/video/update`,
        data
      )
        .then(() => {
          toast("Successfully Updated", {
            position: "bottom-center",
            type: "success",
          });
          window.location.reload();
        })
        .catch((e) => {
          toast.error('Error while saving article', e);
          console.error(e);
        });
    }
  };

  const onDeleteData = () => {
    const data = { video_id: videoList?.id };
    axios.post(
      `${config.serverURL}admin/video/delete`,
      data
    )
      .then(() => {
        toast("Successfully Deleted", {
          position: "bottom-center",
          type: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleTabs = (e, val) => {
    setValue(val);
  };

  const handlePoseChange = (selectedOptions) => {
    setRelatedYogaPoses(selectedOptions.map(option => option.value));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 'auto',
      width: '90%',
      fontSize: 15,
      marginTop: 10,
      marginBottom: 10,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 15,
    }),
  };

  return (
    <>
      <div className="formadj">
        <div className="row">
          <div className="col-12">
            <p className="information">
              {props.addnew ? <>VIDEO<span className="bolding"> CREATE</span></> : <>VIDEO<span className="bolding"> INFO</span></>}
            </p>
            <AppBar position="static">
              <Tabs value={value} onChange={handleTabs}>
                <Tab label="Video Details" />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
              <br />
              <div className="row">
                <div className="col-12">
                  <label>Video Title</label>
                  <input
                    className="form"
                    id="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Video Url</label>
                  <input
                    className="form"
                    id="url"
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="row">
                <div className="col-12">
                  <label>Description</label>
                  <textarea
                    className="form"
                    id="description"
                    rows={5}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div> */}
              <div className="row">
                <div className="col-12">
                  <label>Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                    config={{
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                      image: {
                        toolbar: [
                          'imageTextAlternative',
                          'toggleImageCaption',
                          'imageStyle:inline',
                          'imageStyle:block',
                          'imageStyle:side',
                          'uploadImage',
                        ],
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label>Related Yoga Poses</label>
                  <Select
                    isMulti
                    value={yogaPoses.filter(pose => related_yoga_poses.includes(pose.value))}
                    options={yogaPoses}
                    onChange={handlePoseChange}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    onClick={onDeleteData}
                    className="form"
                    style={{
                      borderWidth: 0,
                      backgroundColor: "red",
                      borderRadius: 10,
                      color: "white",
                      fontSize: 15,
                      padding: 10,
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={onChangeHandler}
                    className="form"
                    style={{
                      borderWidth: 0,
                      backgroundColor: "#17545E",
                      borderRadius: 10,
                      color: "white",
                      fontSize: 15,
                      padding: 10,
                    }}
                  >
                    {props.addnew ? 'Create' : 'Save'}
                  </button>
                </div>
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <div>{children}</div>}</div>;
}

export default VideoInfo;
