

import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from "../../sidebar";
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import Overlay from "react-overlay-component";
import 'react-pagination-bar/dist/index.css';
import { useNavigate } from "react-router-dom";
import config from "../../config";
import YogaPoses from "./YogaPoses";

function YogaPosesList() {
    const [data, setData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagePostsLimit, setpageSize] = useState(10);
    const [modalOpened, setmodalOpened] = useState(false);
    const [modalOpened3, setmodalOpened3] = useState(false);
    const [modalOpened4, setmodalOpened4] = useState(false);
    const [selectedBuyer, setSelectedBuyer] = useState({});
    const [filter, setFilter] = useState("");
    const [modal, setModal] = useState(false);
    const [modalOpen1, setModalOpen1] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [records, setRecords] = useState(0);
    const [search, setSearch] = useState("");
    const animate = true;
    const navigate = useNavigate();
    const [pending, setPending] = useState("");
    const [verified, setVerified] = useState("");
    const [rejected, setRejected] = useState("");
    const [signed, setSigned] = useState("");
    const [createDate, setCreateDate] = useState("");
    const [verifyDate, setVerifyDate] = useState("");
    const [signDate, setSignDate] = useState("");
    const [isaddnew, setisaddnew] = useState(false)
    const [categoryData, setcategoryData] = useState([])
    const [img, setImg] = useState("");

    const [order, setOrder] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [sortType, setSortType] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [category_id, setcategory_id] = useState('')
    const setFile = (e) => {
        console.log("Entered");
        console.log(e.target.files[0].name);
        setImg(e.target.files[0].name);
    }
    // Function to remove HTML tags from a string
    const stripHtmlTags = (html) => {
        // Create a temporary DOM element to use for tag stripping
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    const setCheckBox = (e) => {
        console.log("Entered");
        const value = e.target.value;

        //! FILTER   
        if (e.target.id.includes("active")) {
            setFilterStatus(true);
        }
        if (e.target.id.includes("inactive")) {
            setFilterStatus(false);
        }
        if (e.target.id.includes("not")) {
            setFilterStatus("");
        }

        //! SORT
        if (e.target.id.includes("ASC")) {
            setSortType("asc");
        }
        if (e.target.id.includes("DESC")) {
            setSortType("desc");
        }
        if (e.target.id.includes("N")) {
            setSortType("");
        }

        setOrder(value);
        setSortBy("createdAt");
    }

    const onChangeHandler = (e) => {
        const value = e.target.value;

        if (e.target.id == "search") {
            if (value == "") {
                getData()
            }
            setSearch(value);
            //applySearch();
        }
    }


    const enableForm = () => {
        setmodalOpened(true);
        console.log(modalOpened);
    }

    const closeOverlay = () => {
        setisaddnew(false)
        setmodalOpened(false);
        // setmodalOpened2(false);
        setmodalOpened3(false);
        setmodalOpened4(false);
        console.log(currentPage);
        getData(currentPage);
    }

    const getUserInfoForm = (obj) => {
        console.log(obj);
        setShowForm(true);
        setmodalOpened4(true);
        console.log(modalOpened4);
        setSelectedBuyer(obj);
    }

    function applyFilters(pageNumber = 1) {
        console.log("Entered");

        if (filterStatus === "") getData();
        else {
            axios.get(`${config.serverURL}/societies?filter={"offset": 0,"limit": ${pagePostsLimit},"skip": ${(pageNumber - 1) * pagePostsLimit},"order": "${sortType !== "" ? `${sortBy} ${sortType}` : "string"}","where": {}}`)
                .then((res) => {
                    setData(res.data);
                    console.log(res.data);
                    setModalOpen1(false);
                })
            axios.get(`${config.serverURL}/societies/count?where={}`)
                .then((res) => {
                    setRecords(res.data.count);
                })
        }
    }

    const filterForm = () => {
        setShowForm(true);
        setmodalOpened3(true);
        console.log(modalOpened3);
    }

    const onChangeHandler1 = (e) => {
        const value = moment(e.target.value).format('L');
        if (e.target.id == "1") {
            setCreateDate(value);
        }
        if (e.target.id == "2") {
            setVerifyDate(value);
        }
        if (e.target.id == "3") {
            setSignDate(value);
        }
    }

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
        getData(pageNumber);
    }



    function applySearch(pageNumber = 1) {
        axios.get(`${config.serverURL}/societies?filter={"offset": 0,"limit": ${pagePostsLimit},"skip": ${(pageNumber - 1) * pagePostsLimit},"order": "createdAt desc","where": {"or": [{"name":{"regexp":"/${search}/ig"}},{"area":{"regexp":"/${search}/ig"}}]}}`)
            .then((res) => {
                setData(res.data);
            })

        axios.get(`${config.serverURL}/societies/count?where={ "or": [{"name":{"regexp":"/${search}/ig"}},{"area":{"regexp":"/${search}/ig"}}] }`)
            .then((res) => {
                setRecords(res.data.count);
            });
    }

    function applySort(pageNumber = 1) {
        if (sortType === "") getData();
        else {

            axios.get(`${config.serverURL}/societies?filter={"offset":0,"limit":100,"skip":0,"order":"${sortBy} ${sortType}"}`)
                .then((res) => {
                    setData(res.data);
                    console.log(res.data);
                    setModalOpen2(false);
                });
            axios.get(`${config.serverURL}/societies/count`)
                .then((res) => {
                    setRecords(res.data.count);
                });
        }
    }

    const [users, setUsers] = useState([]);

    function getData(pageNumber = 1) {

        console.log(pageNumber);

        // axios.get(`${config.serverURL}admin/yogapose/bycategory/get?category_id=${category_id}`)
        axios.get(`${config.serverURL}admin/yogapose/getall`)
            .then((res) => {
                console.log(res.data.data, ".....")
                setData(res.data.data);
                console.log(res.data);
                setRecords(res.data.data?.length);
            }).catch((e) => {
                console.log(e);

            })


    }
    const getCategory = () => {
        axios.get(`${config.serverURL}admin/posecategory/getall`)
            .then((res) => {
                setcategoryData(res.data.data);
                setcategory_id(res?.data?.data?.[0]?.id)
            }).catch((e) => {

            })
    }

    useEffect(() => {
        getData();
    }, [category_id]);
    useEffect(() => {

        getCategory()

    }, [])

    return (
        <>
            <section>
                <div className="row">

                    <div className="col-12">
                        <div className="col-6">
                            <Overlay configs={animate} isOpen={modalOpened4} closeOverlay={closeOverlay}>
                                {modalOpened4 && <YogaPoses addnew={isaddnew} categoryData={selectedBuyer} />}
                            </Overlay>
                        </div>
                    </div>
                    <Sidebar />
                    <div className="col-lg-11 col-10">
                        <div className="row upperhead">
                            <div className="col-4">
                            <img className="tracker" style={{marginTop:20}} src={require("../../images/app_Logo.png")}/>
                                <p className="invoice-tracker"><span className="invoice">Yoga Poses</span> TRACKER</p>
                                <p className="tracker">Tracker to monitor all Yoga Poses.</p>
                            </div>
                            <div className="col-4">
                                <p className="showingrecords">Showing Records...{records}</p>
                            </div>
                            <div className="col-14" style={{ textAlign: 'right', display: 'block' }}>
                                <button type="button" className="btn btn-primary" style={{ display: 'inline-block', margin: 'unset', marginTop: '50px', marginRight: '30px', background: 'white', fontWeight: 600, color: '#111', border: 'solid 2px #111' }} onClick={() => { setisaddnew(true); setmodalOpened4(true) }}>       New Yoga Poses      </button>
                            </div>
                        </div>
                        <div className="row upperhead py-3"  >
                            <div className="col-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                                <input id="search" className="search" type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />

                            </div>

                            <div className="col-4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                                <select

                                    value={category_id}
                                    onChange={(e) => setcategory_id(e.target.value)}
                                    style={{ fontSize: "12px" }}
                                    className="search"
                                    id="partnerId"
                                    name="enabled"
                                >
                                    <option value="" defaultChecked>
                                        Select Category
                                    </option>
                                    {categoryData?.map((option) => {
                                        return (
                                            <>
                                                <option value={option?.id}>
                                                    {option?.name}
                                                </option>
                                            </>
                                        );
                                    })}
                                </select>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-12 setheight2">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {/* <th>IS USER ACTIVE</th>    */}
                                            <th>NAME</th>
                                            <th>SHORT DESCRIPTION</th>
                                            <th>LONG DESCRIPTION</th>
                                            <th>DRAFT</th>
                                            <th>POSE TYPE</th>
                                            <th>SANSKRIT NAME</th>
                                            <th>BENEFITS</th>
                                            <th>TARGETS</th>
                                            <th>GUIDANCE</th>
                                            <th>THINGS KEEP IN MIND</th>
                                            <th>IMAGE</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.filter(
                                            (item) => (item.name ? item.name.toLowerCase().includes(search.toLowerCase()) : false)
                                        ).map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="invoiceNo" onClick={() => getUserInfoForm(item)}>
                                                        {item.name}
                                                    </td>
                                                    <td>{stripHtmlTags(item?.short_description?.slice(0, 100) + '....')}</td>
                                                    <td>{stripHtmlTags(item?.long_description?.slice(0, 100) + '....')}</td>
                                                    <td>{stripHtmlTags(item?.draft)}</td>
                                                    <td>{stripHtmlTags(item?.pose_type)}</td>
                                                    <td>{stripHtmlTags(item?.sanskrit_meaning)}</td>
                                                    <td>{stripHtmlTags(item?.benefits?.slice(0, 100) + '....')}</td>
                                                    <td>{stripHtmlTags(item?.targets)}</td>
                                                    <td>{stripHtmlTags(item?.guidance?.slice(0, 100) + '....')}</td>
                                                    <td>{stripHtmlTags(item?.things_keep_in_mind?.slice(0, 100) + '....')}</td>
                                                    <td>
                                                        <img src={config.serverURL + item?.image} height={50} width={50} alt={item.name} />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>

                                </table>
                            </div>

                        </div>


                    </div>
                </div>

            </section>
        </>
    );
}

export default YogaPosesList;