import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import config from "../../config";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return new Promise((resolve, reject) => {
      const file = this.loader.file;
      console.log("File received:", file);
      console.log("Is file a Promise?", file instanceof Promise);

      if (file instanceof Promise) {
        file.then(actualFile => {
          console.log("Actual file after resolving promise:", actualFile);
          this._processFile(actualFile, resolve, reject);
        }).catch(err => {
          console.error("Error resolving file promise:", err);
          reject("File promise resolution failed.");
        });
      } else {
        this._processFile(file, resolve, reject);
      }
    });
  }

  _processFile(file, resolve, reject) {
    console.log("Processing file:", file);

    if (file instanceof File) {
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if (validTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = reader.result;
          resolve({
            default: base64String,
          });
        };

        reader.onerror = () => {
          reject("Couldn't read the file.");
        };

        reader.readAsDataURL(file);
      } else {
        reject("Invalid file type. Please upload a jpg, jpeg, or png image.");
      }
    } else {
      reject("The provided file is not a valid File object.");
    }
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }
}


function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

function YogaPoses(props) {
  const [value, setValue] = useState(0);
  const categoryData = props?.categoryData;
  const [customerData, setcustomerData] = useState([]);
  const [categoryDatad, setcategoryData] = useState([]);
  const [name, setname] = useState(categoryData?.name || '');
  const [short_description, setShortDescription] = useState(categoryData?.short_description || '');
  const [long_description, setlong_description] = useState(categoryData?.long_description || '');
  const [image, setimage] = useState(categoryData?.image || '');
  const [pose_type, setposetype] = useState(categoryData?.pose_type || '');
  const [sanskrit_meaning, setsanskrit_meaning] = useState(categoryData?.sanskrit_meaning || '');
  const [benefits, setbenefits] = useState(categoryData?.benefits || '');
  const [targets, settargets] = useState(categoryData?.targets || '');
  const [guidance, setguidance] = useState(categoryData?.guidance || '');
  const [things_keep_in_mind, setthings_keep_in_mind] = useState(categoryData?.things_keep_in_mind || '');
  const [category_id, setcategory_id] = useState(categoryData?.category_id || '');
  const [meta_tag, setMetaTag] = useState(JSON.parse(categoryData?.meta_tag) || '');
  const [draft, setDraft] = useState(categoryData?.draft || 0);
  console.log(categoryData, "...")

  const handleDraft = async () => {
    let data = {
      name, category_id, short_description, long_description, pose_type, sanskrit_meaning, benefits, targets, guidance, things_keep_in_mind,
      // meta_tag: JSON.stringify(meta_tag),
      draft: 1,
      ...(categoryData && { pose_id: categoryData.id }),
    };

    if (image && image instanceof File) {
      const base64Img1 = await convertToBase64(image);
      console.log(base64Img1, image, "....>>")
      data.image = base64Img1; // Add only if changed
    } 
    console.log(data, "datattatat")

    if (!name || !category_id || !image || !short_description || !long_description || !pose_type || !sanskrit_meaning || !benefits || !targets || !guidance || !things_keep_in_mind) {
      toast.error('Invalid Details');
      return;
    }
    const res = await axios.post(`${config.serverURL}admin/yogapose/${props.addnew ? 'add' : 'update'}`, data)
      .then(() => {
        toast("Successfully saved as Draft", {
          position: "bottom-center",
          type: "success",
        });
        // console.log(res,"...>>")
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
        toast.error('Error while saving draft', e);
      });

    }


  const onChangeHandler = async () => {
    let data = {
      name, category_id, short_description, long_description, pose_type, sanskrit_meaning, benefits, targets, guidance, things_keep_in_mind, meta_tag: JSON.stringify(meta_tag), draft: 0,
    };



    if (props.addnew) {
      if (!name || !category_id || !image || !short_description || !long_description || !pose_type || !sanskrit_meaning || !benefits || !targets || !guidance || !things_keep_in_mind) {
        toast.error('Invalid Details');
        return;
      }

      if (image && image instanceof File) {
        const base64Img1 = await convertToBase64(image);
        console.log(base64Img1, image, "....>>")
        data.image = base64Img1; // Add only if changed
      } else {
        data.image = image
      }
      try {
        await axios.post(`${config.serverURL}admin/yogapose/add`, data);
        toast.success("Successfully Created");
        window.location.reload();
      } catch (e) {
        toast.error('Error while saving article');
        console.error(e);
      }
    } else {
      try {
        const base64 = await fetch(image)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((res) => {
              reader.onloadend = () => {
                res(reader.result);
              };
            });
          });

        // data = { pose_id: categoryData?.id, name, short_description, long_description, category_id: categoryData?.category_id, image, pose_type, sanskrit_meaning, benefits, targets, guidance, things_keep_in_mind };
        data = { pose_id: categoryData?.id, name, short_description, long_description, category_id: categoryData?.category_id, pose_type, sanskrit_meaning, benefits, targets, guidance, things_keep_in_mind, meta_tag: JSON.stringify(meta_tag), draft: 0 };

        if (image && image instanceof File) {
          const base64Img1 = await convertToBase64(image);
          console.log(base64Img1, image, "....>>")
          data.image = base64Img1; // Add only if changed
        }

        console.log(data, "..dataaa")
        const res = await axios.post(`${config.serverURL}admin/yogapose/update`, data);

        if (res?.status === 200) {
          toast.success("Successfully Updated");
        } else {
          toast.error('Update failed');
        }
        window.location.reload();
      } catch (e) {
        toast.error('Error while saving article');
        console.error(e);
      }
    }
  };

  // const handleFileUpload = async (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const base64 = await convertToBase64(file);
  //     setimage(base64);
  //   }
  // };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    //const base64 = await convertToBase64(file);
    // console.log(base64);
    setimage(file);
  };

  const convertToBase64 = (file) => {
    console.log(file)
    return new Promise(async (resolve, reject) => {
      //const response = await fetch(file);
      //const blob = await response.blob();
      const blob = new Blob([file], { type: file.type });
      console.log("Blob: " + blob + "/" + file.type)
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const result = fileReader.result
        resolve(result.toString());
        console.log(result)
      };
      fileReader.readAsDataURL(blob);
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleMetaTagChange = (e) => {
    setMetaTag(e.target.value);
  };


  const onDeleteData = () => {
    const data = { pose_id: categoryData?.id };
    axios.post(`${config.serverURL}admin/yogapose/delete`, data)
      .then(() => {
        toast.success("Successfully Deleted");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((e) => {
        toast.error('Error while deleting');
        console.error(e);
      });
  };

  const handleTabs = (e, val) => {
    setValue(val);
  };

  function getData() {
    axios.get(`${config.serverURL}admin/posecategory/getall`)
      .then((res) => {
        setcategoryData(res?.data?.data || []);
      })
      .catch((e) => console.error(e));
  }

  useEffect(() => {
    if (props.addnew) {
      setname('');
      setcategory_id('');
      setShortDescription('');
      setlong_description('');
      setimage('');
      setposetype('');
      setsanskrit_meaning('');
      setbenefits('');
      settargets('');
      setguidance('');
      setMetaTag('')
      setthings_keep_in_mind('');
    }
    getData();
  }, [props.addnew]);

  return (
    customerData && (
      <>
        <div className="formadj">
          <div className="row">
            <div className="col-12">
              <p className="information">
                {props.addnew ? (
                  <>Yoga Poses<span className="bolding"> CREATE</span></>
                ) : (
                  <>Yoga Poses<span className="bolding"> INFO</span></>
                )}
              </p>
              <AppBar position="static">
                <Tabs value={value} onChange={handleTabs}>
                  <Tab label="Yoga Poses Details" />
                </Tabs>
              </AppBar>

              {/* <TabPanel value={value} index={0}> */}
              <br />
              <div className="row">
                <div className="col-12">
                  <label>Name</label>
                  <input
                    className="form"
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-10">
                <label
                  style={{ textAlign: "left", fontSize: "14px" }}
                >
                  Yoga Poses Category 
                </label>
                <select
                  value={category_id}
                  onChange={(e) => setcategory_id(e.target.value)}
                  style={{ width: "100%", fontSize: "12px" }}
                  className="form"
                  id="partnerId"
                  name="enabled"
                >
                  <option value="" defaultChecked>Select</option>
                  {categoryDatad?.map((option) => (
                    <option key={option?.id} value={option?.id}>
                      {option?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Meta Tag</label>
                  <textarea
                    className="form"
                    rows={4}
                    value={meta_tag}
                    onChange={handleMetaTagChange}
                  // placeholder="Enter meta tag in HTML format"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Short Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={short_description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setShortDescription(data);
                    }}
                    config={{
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                      image: {
                        toolbar: [
                          'imageTextAlternative',
                          'toggleImageCaption',
                          'imageStyle:inline',
                          'imageStyle:block',
                          'imageStyle:side',
                          'uploadImage',
                        ],
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Long Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={long_description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setlong_description(data);
                    }}
                    config={{
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                      image: {
                        toolbar: [
                          'imageTextAlternative',
                          'toggleImageCaption',
                          'imageStyle:inline',
                          'imageStyle:block',
                          'imageStyle:side',
                          'uploadImage',
                        ],
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Pose Type</label>
                  <input
                    className="form"
                    id="pose_type"
                    type="text"
                    value={pose_type}
                    onChange={(e) => setposetype(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Sanskrit Meaning</label>
                  <input
                    className="form"
                    id="sanskrit_meaning"
                    type="text"
                    value={sanskrit_meaning}
                    onChange={(e) => setsanskrit_meaning(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Benefits</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={benefits}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setbenefits(data);
                    }}
                    config={{
                      // extraPlugins: [MyCustomUploadAdapterPlugin],
                      image: {
                        toolbar: [
                          'imageTextAlternative',
                          'toggleImageCaption',
                          'imageStyle:inline',
                          'imageStyle:block',
                          'imageStyle:side',
                          'uploadImage',
                        ],
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Targets</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={targets}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      settargets(data);
                    }}
                    config={{
                      // extraPlugins: [MyCustomUploadAdapterPlugin],
                      image: {
                        toolbar: [
                          'imageTextAlternative',
                          'toggleImageCaption',
                          'imageStyle:inline',
                          'imageStyle:block',
                          'imageStyle:side',
                          'uploadImage',
                        ],
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Guidance</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={guidance}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setguidance(data);
                    }}
                    config={{
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                      image: {
                        toolbar: [
                          'imageTextAlternative',
                          'toggleImageCaption',
                          'imageStyle:inline',
                          'imageStyle:block',
                          'imageStyle:side',
                          'uploadImage',
                        ],
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Things Keep In Mind</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={things_keep_in_mind}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setthings_keep_in_mind(data);
                    }}
                    config={{
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                      image: {
                        toolbar: [
                          'imageTextAlternative',
                          'toggleImageCaption',
                          'imageStyle:inline',
                          'imageStyle:block',
                          'imageStyle:side',
                          'uploadImage',
                        ],
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <label>Yoga poses Image</label>
                  {/* <img
                      onClick={() =>
                        window.location.replace(config.serverURL + image)
                      }
                      style={{ width: 200, height: 200 }}
                      src={
                        image?.startsWith("storage")
                          ? config.serverURL + image
                          : image
                      }
                      alt=""
                    />
                    <input
                      onChange={handleFileUpload}
                      type="file"
                      name={props.addnew ? "Upload Image" : "Change Image"}
                    /> */}
                    <img
  onClick={() => {
    const imageUrl = typeof image === "string" ? config.serverURL + image : image;
    return props.addnew ? imageUrl : imageUrl;
  }}
  style={{ width: 200, height: 200 }}
  src={typeof image === "string" ? config.serverURL + image : image}
  alt={props.addnew ? "New image" : "Existing image"}
  srcSet={image.srcSet || ""}  // If `image` has a `srcSet`, you can use it here
/>

                  {/* <img onClick={() => props.addnew ? typeof image == "string" ? config.serverURL + image : image : image} style={{ width: 200, height: 200 }} src={props.addnew ? typeof image == "string" ? config.serverURL + image : image : image} alt="" srcset="" /> */}
                  <input onChange={handleFileUpload} type="file" title={props.addnew ? "upload Image" : "Change Image"} />
                </div>
                <div className="col-6">
                
                    {/* Draft Button */}
                    <button
                      onClick={handleDraft}
                      className="form mt-3"
                      style={{
                        borderWidth: 0,
                        backgroundColor: "#17545E",
                        borderRadius: 10,
                        color: "white",
                        fontSize: 15,
                        padding: 10,
                      }}
                    >
                      Draft
                    </button>
                   
                  <button
                    onClick={onChangeHandler}
                    className="form"
                    style={{
                      borderWidth: 0,
                      backgroundColor: "#17545E",
                      borderRadius: 10,
                      color: "white",
                      fontSize: 15,
                      padding: 10,
                    }}
                  >
                    {props.addnew ? "Create" : "Save"}
                  </button>
                  <br />
                  <button
                    onClick={onDeleteData}
                    className="form"
                    style={{
                      borderWidth: 0,
                      backgroundColor: "red",
                      borderRadius: 10,
                      color: "white",
                      fontSize: 15,
                      padding: 10,
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
              {/* </TabPanel> */}
              {!props.addnew && (
                <TabPanel value={value} index={1}>
                  <br />
                  <div className="row">
                    <div className="col-6">
                      <label>Is User Active</label>
                      <input
                        className="form"
                        id="isUserActive"
                        type="text"
                        value={customerData.isUserActive ? "Yes" : "No"}
                        readOnly
                      />
                    </div>
                  </div>
                </TabPanel>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    )
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default YogaPoses;
