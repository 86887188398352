import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";
import config from "../../config";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return new Promise((resolve, reject) => {
      const file = this.loader.file;
      console.log("File received:", file);
      console.log("Is file a Promise?", file instanceof Promise);

      if (file instanceof Promise) {
        file.then(actualFile => {
          console.log("Actual file after resolving promise:", actualFile);
          this._processFile(actualFile, resolve, reject);
        }).catch(err => {
          console.error("Error resolving file promise:", err);
          reject("File promise resolution failed.");
        });
      } else {
        this._processFile(file, resolve, reject);
      }
    });
  }

  _processFile(file, resolve, reject) {
    console.log("Processing file:", file);

    if (file instanceof File) {
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if (validTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = reader.result;
          resolve({
            default: base64String,
          });
        };

        reader.onerror = () => {
          reject("Couldn't read the file.");
        };

        reader.readAsDataURL(file);
      } else {
        reject("Invalid file type. Please upload a jpg, jpeg, or png image.");
      }
    } else {
      reject("The provided file is not a valid File object.");
    }
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }
}


function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

function ArticleInfo(props) {
  console.log(props);
  // console.log(props.productInfo.productId);
  const [value, setValue] = useState(0);
  const categoryData = props?.categoryData
  const [customerData, setcustomerData] = useState([]);
  const [vehicleData, setVehicleData] = useState({});
  const [name, setname] = useState(categoryData?.name)
  const [title, settitle] = useState(categoryData?.title)
  const [short_description, setshort_description] = useState(categoryData?.short_description || "")
  const [long_description, setlong_description] = useState(categoryData?.long_description)
  const [image, setimage] = useState(categoryData?.image)
  const [author_name, setauthor_name] = useState(categoryData?.author_name)
  const [author_image, setauthor_image] = useState(categoryData?.author_image)
  const [author_details, setauthor_details] = useState(categoryData?.author_details)
  const [category_id, setcategory_id] = useState(categoryData?.category_id)
  const [categoryDatad, setcategoryData] = useState([])
  const [related_yoga_poses, SetRelatedYogaPoses] = useState(categoryData?.related_yoga_poses || []);
  const [is_latest, setLatest_Article] = useState(categoryData?.is_latest || 0);
  const [is_expert_approved, setExpert_Approved] = useState(categoryData?.is_expert_approved || 0);
  const [draft, setDraft] = useState(categoryData?.draft || 0);
  const [yogaPoses, setYogaPoses] = useState([]);
  const [meta_tag, setMetaTag] = useState(JSON.parse(categoryData?.meta_tag) || '');

  console.log("config",config)
  useEffect(() => {

    axios.get(`${config.serverURL}admin/yogapose/getall`)
      .then(response => {
        if (response.data.status_code === 200) {
          setYogaPoses(response.data.data.map(pose => ({
            value: pose.id,
            label: pose.name,
          })));
        }
      })
      .catch(error => {
        console.error("There was an error fetching the yoga poses!", error);
      });
  }, []);

  const handleDraft = async () => {
    let data = {
      title,
      short_description,
      long_description,
      // image,
      author_name,
      // author_image,
      author_details,
      category_id,
      related_yoga_poses,
      is_latest,
      is_expert_approved,
      draft: 1,
      meta_tag: JSON.stringify(meta_tag),
      ...(categoryData && { artical_id: categoryData.id }),
    };

    console.log(data, "datattatat")

    if (title.length < 0 || !short_description || !long_description || !image || !author_name || !author_details || !author_image || !category_id || !is_latest || !is_expert_approved) {
      toast.error('Invalid Details');
    
      return;
    }

    if (author_image && author_image instanceof File) {
      const base64Img = await convertToBase64(author_image);
      data.author_image = base64Img;
    }


    if (image && image instanceof File) {
      const base64Img1 = await convertToBase64(image);
      data.image = base64Img1;
    }


    console.log(data, "datttata22", config.serverURL, image, author_image)
    const res = await axios.post(`${config.serverURL}admin/artical/${props.addnew ? 'add' : 'update'}`, data)
      .then(() => {
        toast("Successfully saved as Draft", {
          position: "bottom-center",
          type: "success",
        });
        // console.log(res,"...>>")
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
        toast.error('Error while saving draft', e);
      });
  };

  const onChangeHandler = async () => {
    let data = {
      title, short_description, long_description, image, author_name, author_image, author_details, category_id, related_yoga_poses, is_latest, is_expert_approved, draft: 0, meta_tag: JSON.stringify(meta_tag)
    };
    if (props.addnew) {

      if (title.length < 0 || !short_description || !long_description || !image || !author_name || !author_details || !author_image || !category_id || !is_latest || !is_expert_approved) {
        toast.error('invalid Details')
        console.log(data, "...data")
        return;
      }
      // Check if the author_image has changed and convert if necessary
      if (author_image && author_image instanceof File) {
        const base64Img = await convertToBase64(author_image);
        data.author_image = base64Img; // Add only if changed
      }

      // Check if the image has changed and convert if necessary
      if (image && image instanceof File) {
        const base64Img1 = await convertToBase64(image);
        data.image = base64Img1; // Add only if changed
      }

      const res = axios
        .post(
          `${config.serverURL}admin/artical/add`,
          data
        )
        .then(() => {
          toast("Sucessfully Created", {
            position: "bottom-center",
            type: "success",
          });
          window.location.reload()
        })
        .catch((e) => {
          console.log(e);
        });
    }
    else {
      let data = {
        artical_id: categoryData?.id,
        title,
        short_description,
        long_description,
        author_name,
        author_details,
        related_yoga_poses,
        is_expert_approved,
        is_latest,
        draft: 0,
        meta_tag: JSON.stringify(meta_tag)
      };
      if (author_image && author_image instanceof File) {
        const base64Img = await convertToBase64(author_image);
        data.author_image = base64Img; 
      }

      if (image && image instanceof File) {
        const base64Img1 = await convertToBase64(image);
        data.image = base64Img1; // Add only if changed
      }
      const res = axios
        .post(
          `${config.serverURL}admin/artical/update`,
          data
        )
        .then((e) => {
          if (e?.status == 200) {
            toast("Sucessfully Updated", {
              position: "bottom-center",
              type: "success",
            });
          }
          else {
            toast.error(JSON.stringify(e));
          }

          window.location.reload()
        })
        .catch((e) => {
          toast.error('error while saving Article', e)
          console.error(e)
          console.log(e);
        });
    }
  };
  const handlePoseChange = (selectedOptions) => {
    SetRelatedYogaPoses(selectedOptions.map(option => option.value));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 'auto',
      width: '90%',
      fontSize: 15,
      marginTop: 10,
      marginBottom: 10,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 15,
    }),
  };

  const getBlobFromPath = async (path) => {
    const response = await fetch(path);
    const blob = await response.blob();
    return blob;
  };


  const convertToBase64 = (file) => {
    console.log(file)
    return new Promise(async (resolve, reject) => {
      //const response = await fetch(file);
      //const blob = await response.blob();
      const blob = new Blob([file], { type: file.type });
      console.log("Blob: " + blob + "/" + file.type)
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const result = fileReader.result
        resolve(result.toString());
        console.log(result)
      };
      fileReader.readAsDataURL(blob);
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    //const base64 = await convertToBase64(file);
    // console.log(base64);
    setimage(file);
  };

  const handleFileUploadAuthor = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    //const base64 = await convertToBase64(file);
    // console.log(base64);
    setauthor_image(file);
  };

  const onDeleteData = () => {
    const data = { artical_id: categoryData?.id }
    const res = axios
      .post(
        `${config.serverURL}admin/artical/delete`
        , data
      )
      .then(() => {

        toast("Sucessfully Deleted", {
          position: "bottom-center",
          type: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      })
      .catch((e) => {
        console.log(e);
      });

  }
  const handleMetaTagChange = (e) => {
    setMetaTag(e.target.value);
  };
  function submitHandler() {
    axios
      .patch(
        `${config.serverURL}/users/` + props.voucherInfo.userId,
        customerData,
        {
          headers: {
            token: localStorage.getItem("token"),
            userId: localStorage.getItem("userId"),
          },
        }
      )
      .then((res) => {
        if (res.data.statusname == 200) {
          toast("Sucessfully Updated", {
            position: "bottom-center",
            type: "success",
          });
          getData();
        }
      })
      .catch((err) => {
        console.log(err);
        toast(err.response.data.message, {
          position: "bottom-center",
          type: "error",
        });
      });
  }

  const handleTabs = (e, val) => {
    console.log(val);
    setValue(val);
  };

  function getData() {
    axios
      .get(
        `${config.serverURL}admin/articalcategory/getall`
      )
      .then((res) => {
        console.log(res?.data?.data);

        setcategoryData(res?.data?.data)
      }).catch((e) => console.log(e)
      );
  }

  useEffect(() => {
    if (!props.addnew) {
      SetRelatedYogaPoses(categoryData.related_yoga_poses?.map(pose => pose.id) || []);
    }
    else {
      if (props?.addnew) {
        settitle('')
        setcategory_id('')
        setauthor_details('')
        setauthor_image('')
        setshort_description('')
        setlong_description('')
        setauthor_name('')
        setimage('')
        SetRelatedYogaPoses([])
        setExpert_Approved(0)
        setLatest_Article(0)
        setDraft(0)
        setMetaTag('')
      }

      const arr = ['', '', '', '', '']
      //   setbenefits(arr)
    }
    getData();
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    customerData && (
      <>
        <div className="formadj">
          <div className="row">
            <div className="col-12">
              <p className="information">
                {
                  props.addnew ? <>Article<span className="bolding"> CREATE</span></> : <>Article<span className="bolding"> INFO</span></>
                }

              </p>
              <AppBar position="static">
                <Tabs value={value} onChange={handleTabs}>
                  <Tab label="Article Details" />

                </Tabs>
              </AppBar>

              <br />
              <div className="row">
                <div className="col-12">
                  <label>Title</label>
                  <input
                    className="form"
                    id="name"
                    type="text"
                    value={title}
                    onChange={(e) => {
                      settitle(e.target.value);
                    }}
                  ></input>
                </div>

              </div>
              <div className="col-10">
                <label
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Article Category
                </label>
                <select
                  value={category_id}
                  onChange={(e) => setcategory_id(e.target.value)}
                  style={{ width: "100%", fontSize: "12px" }}
                  className="form"
                  id="partnerId"
                  name="enabled"
                >
                  <option value="" defaultChecked>
                    Select
                  </option>
                  {categoryDatad?.map((option) => (
                    <option key={option?.id} value={option?.id}>
                      {option?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Meta Tag</label>
                  <textarea
                    className="form"
                    rows={4}
                    value={meta_tag}
                    onChange={handleMetaTagChange}
                    // placeholder="Enter meta tag in HTML format"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Short Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={short_description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setshort_description(data);
                    }}
                    config={{
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                      image: {
                        toolbar: [
                          'imageTextAlternative',
                          'toggleImageCaption',
                          'imageStyle:inline',
                          'imageStyle:block',
                          'imageStyle:side',
                          'uploadImage',
                        ],
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label>Long Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={long_description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setlong_description(data);
                    }}
                    config={{
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                      image: {
                        toolbar: [
                          'imageTextAlternative',
                          'toggleImageCaption',
                          'imageStyle:inline',
                          'imageStyle:block',
                          'imageStyle:side',
                          'uploadImage',
                        ],
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label>Related Yoga Poses</label>
                  <Select
                    isMulti
                    value={yogaPoses.filter(pose => related_yoga_poses.includes(pose.value))}
                    options={yogaPoses}
                    onChange={handlePoseChange}
                    styles={customStyles}
                  />
                </div>
              </div>

              <div className="col-8">
                <label>Latest Article</label>
                <select
                  className="form"
                  value={is_latest}
                  style={{ fontSize: 20, width: '100%' }}
                  onChange={(e) => {
                    setLatest_Article(e.target.value === "1" ? 1 : 0);
                  }}
                >
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </div>
              <div className="col-8">
                <label>Expert Approved</label>
                <select
                  className="form"
                  value={is_expert_approved}
                  style={{ fontSize: 20, width: '100%' }}
                  onChange={(e) => {
                    setExpert_Approved(e.target.value === "1" ? 1 : 0);
                  }}
                >
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </div>

              <div className="row">
                <div className="col-8">
                  <label>Author Name</label>
                  <input
                    className="form"

                    id="name"
                    type="text"
                    value={author_name}
                    onChange={(e) => {
                      setauthor_name(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="col-4">
                  <label>Author Image</label>
                  <img
                    onClick={() => props.addnew ? config.serverURL + (typeof author_image === "string" ? author_image : author_image) : config.serverURL + author_image}
                    style={{ width: 50, height: 50 }}
                    src={props.addnew ? config.serverURL + (typeof author_image === "string" ? author_image : author_image) : config.serverURL + author_image}
                    alt={props.addnew ? 'Add new author image' : 'Author image'}
                    srcSet={author_image.srcSet || ""}
                  />
                  {/* <img onClick={() => props.addnew ? typeof author_image == "string" ? config.serverURL + author_image : config.serverURL +author_image : config.serverURL + author_image} style={{ width: 50, height: 50 }} src={props.addnew ? typeof author_image == "string" ? config.serverURL + author_image : author_image : author_image} alt="" srcset="" /> */}
                  <input onChange={handleFileUploadAuthor} type="file" title={props.addnew ? "upload Image" : "Change Image"}>
                    {/* author_image?.startsWith('storage') ? config.serverURL + author_image : */}
                  </input>
                  {/* <input className="form" id="isUserActive" type="text" value={customerData.isUserActive ? "Yes" : "No"} onChange={onChangeHandler} readOnly></input> */}
                </div>

              </div>
              <div className="row">
                <div className="col-12">
                  <label>Author Details</label>
                  <textarea
                    className="form"
                    rows={2}
                    id="name"
                    type="text"
                    value={author_details}
                    onChange={(e) => {
                      setauthor_details(e.target.value);
                    }}
                  ></textarea>
                </div>

              </div>

              <div className="row">
                <div className="col-6">
                  <label>Article Image</label>
                  <img 
                    onClick={() => props.addnew ? config.serverURL + (typeof image === "string" ? image : image.url) : config.serverURL + image} 
                    style={{ width: 200, height: 200 }} 
                    src={props.addnew ? config.serverURL + (typeof image === "string" ? image : image) : config.serverURL + image} 
                    alt={props.addnew ? 'Add new image' : 'Image'} 
                    srcSet={image.srcSet || ""} 
                  />
                  <input onChange={handleFileUpload} type="file" title={props.addnew ? "upload Image" : "Change Image"}>
                    {/* //typeof image == "string" */}
                  </input>
                  {/*image?.startsWith('storage') ? config.serverURL + image :  <input className="form" id="isUserActive" type="text" value={customerData.isUserActive ? "Yes" : "No"} onChange={onChangeHandler} readOnly></input> */}
                </div>
                <div className="col-6">
                  
                    {/* Draft Button */}
                    <button
                      onClick={handleDraft}
                      className="form"
                      style={{
                        borderWidth: 0,
                        backgroundColor: "#17545E",
                        borderRadius: 10,
                        color: "white",
                        fontSize: 15,
                        padding: 10,
                      }}
                    >
                      Draft
                    </button>
                   
                  <button
                    onClick={onChangeHandler}
                    className="form"
                    style={{
                      borderWidth: 0,
                      backgroundColor: "#17545E",
                      borderRadius: 10,
                      color: "white",
                      fontSize: 15,
                      padding: 10,
                    }}
                  >
                    {props.addnew ? 'Create' : 'Save'}
                  </button>
                  <br />

                  <br />
                  <button
                    onClick={onDeleteData}
                    className="form"
                    style={{
                      borderWidth: 0,
                      backgroundColor: "red",
                      borderRadius: 10,
                      color: "white",
                      fontSize: 15,
                      padding: 10,
                    }}
                  >
                    {"Delete"}
                  </button>

                </div>

              </div>
              <div className="row">
                <div className="col-6">

                </div>
              </div>

              {/* </TabPanel> */}
              {!props.addnew && <TabPanel value={value} index={1}>
                <br />
                <div className="row">
                  <div className="col-6">
                    <label>Is User Active</label>
                    <input
                      className="form"
                      id="isUserActive"
                      type="text"
                      value={customerData.isUserActive ? "Yes" : "No"}
                      onChange={onChangeHandler}
                      readOnly
                    ></input>
                  </div>
                </div>
              </TabPanel>}
            </div>
          </div>
        </div>
        <ToastContainer></ToastContainer>
      </>
    )
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value == index && <h1>{children}</h1>}</div>;
}

export default ArticleInfo;
